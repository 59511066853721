<template>
  <div>
    <appBarMobile
      color="primary"
      show-back-button
      :show-nav-icon="false"
      title="About"
    />
    <span
      class="ma-0 staticPages"
      id="scroll"
    >
      <div>
        <span
          class="pt-4
         pb-7 flex-start"
        >
          <img
            alt="about us image"
            src="../../public/assets/aboutus.svg"
            width="100%"
          >
        </span>
      </div>
      <v-card flat>
        <v-card-text
          class="pa-0"
        >
          <p class="text--default">The DoE Staff Portal App saves you needing to use the browser on your mobile device to access the Staff Portal and many Department services. It delivers a smoother user experience when accessing your personal DoE Portal when you are on the go. It provides greater integration and faster authentication to the various department systems that are available to your staff logon account. Through Push Notifications, you’ll be alerted to required actions and tasks, as well as latest SchoolBiz announcements.</p>
          <p class="text--default">Please keep the DoE Staff Portal app updated, to gain new functionality as it is developed and released. If you have any ideas on how we can make your experience with this app better, please share your feedback with us.</p>
        </v-card-text>
      </v-card>
      <v-list-item
        role="listitem"
        style="border-top: 1px solid #A0A5AE;border-bottom: 1px solid #A0A5AE;"
        class="mx-n4 mt-11 d-flex flex-row justify-space-between text--default"
      ><v-list-item-content>Version (Build {{ this.appBuild }})(PWA Build 230)</v-list-item-content><v-list-item-action>{{ this.appVersion }}</v-list-item-action></v-list-item>
      <v-list-item
        to="ContactUs"
        style="border-bottom: 1px solid #A0A5AE;"
        class="mx-n4 text--default"
      >Contact Us</v-list-item>
      <v-list-item
        role="listitem"
        @click="sendFeedback()"
        style="border-bottom: 1px solid #A0A5AE;"
        class="mx-n4 text--default"
      >Feedback</v-list-item>
    </span>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import appBarMobile from "@/components/appBarMobile";
  export default {
    created(){
      window.scrollTo(0,0);
      //eslint-disable-next-line
      NativeJS.profileAboutTapped();
    },
    components: {
      appBarMobile
    },
    methods:{
      sendFeedback(){
        //eslint-disable-next-line
        NativeJS.profileFeedbackTapped();
        //eslint-disable-next-line
        NativeJS.mailTo("feedback.staffportalapp@det.nsw.edu.au");
      },
      forTesting(){
        this.$store.dispatch("fetchMyNotifications",{id_token:'',auth_token:'',user_name:'',refresh_token:''});
      }
    },
    computed:{
      ...mapGetters({
        tokens:'tokens'
      }),
      appVersion(){
        return this.tokens && this.tokens.appVersion ? this.tokens.appVersion : '1.0';
      },
      appBuild(){
        return this.tokens && this.tokens.appBuild ? this.tokens.appBuild : '1.0';
      }
    }
  }
</script>

<style scoped>
.text--default{
  color:#121212;
}
</style>